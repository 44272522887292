import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Logo from "./logo"

const Header = ({ siteTitle }) => (
  <header className="main-header"
    style={{
      background: `white`,
      marginBottom: `1.45rem`,
    }}
  >
      <h1 className="main-heading" style={{ margin: 0 }}>     
        <Link
          to="/"
          style={{
            color: `black`,
            textDecoration: `none`,
          }}
        >
          <div className="main-logo"><Logo /></div>
          {siteTitle}
        </Link>
      </h1>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
