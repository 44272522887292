import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-blog.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{` To further our corporate wellness campaign, our next stop was at SAP Ariba located in Bellandur. We focused on two important concepts for a healthy lifestyle – Adult vaccination and preventing 'Burn Out'.`}</p>
    <p>{`Dr. Shalini who spearheads our family medicine department spoke about adult vaccination, it’s importance and needs. The importance that we give to adult vaccines pales in comparison to the effort we take to ensure our children are vaccinated. Dr. Shalini spoke about how vaccines are not to be limited to children but are essential to adults especially the elderly, to ensure a healthy life. In fact, the Center for Disease Control (CDC) recommends vaccines for adults and the elderly. For more on vaccines visit: `}<a parentName="p" {...{
        "href": "https://www.pcmhrestorehealth.com/adult-vaccination"
      }}>{`https://www.pcmhrestorehealth.com/adult-vaccination`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5533b9600eee19235dbf1029d09848ab/b0a15/corporate-wellness-talk-at-sap-ariba-1.webp",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/webp;base64,UklGRqwAAABXRUJQVlA4IKAAAADwAwCdASoUAA8APtFUo0uoJKMhsAgBABoJZgCdAYyKlyOknGFVkMaAAP6UTNEK23ErMlvSqUTpjX4zssmpruXczR6npA8EFjtMf5SR+k5mMgUO9+4ddgO+nLJ1ggZzW5PQHEIJkdNQWHd22FvKYNQrjqTdfsX0W2q+zgB4SAFmshT1t5ozBlqotUzjF1BSPqCjR9QRalpMI2oM0VINI+AA')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "corporate wellness talk at sap ariba 1",
            "title": "corporate wellness talk at sap ariba 1",
            "src": "/static/5533b9600eee19235dbf1029d09848ab/b0a15/corporate-wellness-talk-at-sap-ariba-1.webp",
            "srcSet": ["/static/5533b9600eee19235dbf1029d09848ab/c85cb/corporate-wellness-talk-at-sap-ariba-1.webp 300w", "/static/5533b9600eee19235dbf1029d09848ab/b0a15/corporate-wellness-talk-at-sap-ariba-1.webp 500w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4bf5c3effd467e64995964b4a8b2b095/b0a15/corporate-wellness-talk-at-sap-ariba-2.webp",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/webp;base64,UklGRroAAABXRUJQVlA4IK4AAACwBACdASoUAA8APtFUo0uoJKMhsAgBABoJZgCdMoMxg0lR2//LuWYoLj2vrIeYAP4xdFUL0xENZx0DO0QO6VNMPj47m1M+EbRYzc24v6mUxBHDB601Q3qqKwcan7oplEh3NuP+I5eLF27diF4hYFGpXqtvs1mJmyXSV9dRG9Jg9t4pKpOx+Nvk7EJ2arZOx+nks4oscEypuCrzb476cRS5DIbsL7fVoVcWh7xAAAA=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "corporate wellness talk at sap ariba 2",
            "title": "corporate wellness talk at sap ariba 2",
            "src": "/static/4bf5c3effd467e64995964b4a8b2b095/b0a15/corporate-wellness-talk-at-sap-ariba-2.webp",
            "srcSet": ["/static/4bf5c3effd467e64995964b4a8b2b095/c85cb/corporate-wellness-talk-at-sap-ariba-2.webp 300w", "/static/4bf5c3effd467e64995964b4a8b2b095/b0a15/corporate-wellness-talk-at-sap-ariba-2.webp 500w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This was followed by a session on ‘burn out’ by Mr. Aji Joseph, psychologist.`}</p>
    <p>{`Burnout is exhaustion a person experiences in response to chronic emotional stressors arising from life management difficulties. In our busy schedules it is essential to be able to identify events (often overlapping) that lead to burn out. Mr. Aji pointed out signs to look out for to identify and later went on to discuss how to prevent ( psychological wellness ) and how to deal with burnout. To know more about  psyche-excellence services visit: `}<a parentName="p" {...{
        "href": "https://www.pcmhrestorehealth.com/psychological-services"
      }}>{`https://www.pcmhrestorehealth.com/psychological-services`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7cb22579b8455a3c67d6ffeb7ef5591a/b0a15/corporate-wellness-talk-at-sap-ariba-3.webp",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/webp;base64,UklGRtIAAABXRUJQVlA4IMYAAACwBACdASoUAA8APtFUo0uoJKMhsAgBABoJbACxBvVt8cbwLgOSEAOWI7f8nS+gAP7VET1Y5uQgBZNM3aL3snPxFwtMyBGPSjvmUdu5eR9u1jlFElZCTanSu/9ias8iRF9dB3PtYL9ThPmAmbhWujFXK2p6g6ixdudlPPg07YmZZz0BvkDSamZTLUpIQ49PqVn0cqLp5G1U3f/sfvCDSYB7XrTmdnUEs4DKJ++YXIc5ZfJNrJX6kSZaPhk4k25ed8GRvF4YAAA=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "corporate wellness talk at sap ariba 3",
            "title": "corporate wellness talk at sap ariba 3",
            "src": "/static/7cb22579b8455a3c67d6ffeb7ef5591a/b0a15/corporate-wellness-talk-at-sap-ariba-3.webp",
            "srcSet": ["/static/7cb22579b8455a3c67d6ffeb7ef5591a/c85cb/corporate-wellness-talk-at-sap-ariba-3.webp 300w", "/static/7cb22579b8455a3c67d6ffeb7ef5591a/b0a15/corporate-wellness-talk-at-sap-ariba-3.webp 500w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/849f53ae01222180add49ccfb2365bf5/b0a15/corporate-wellness-talk-at-sap-ariba-4.webp",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/webp;base64,UklGRoYAAABXRUJQVlA4IHoAAAAwBACdASoUAA8APtFUo0uoJKMhsAgBABoJYwC+SCHYLCmHQRZinuRlUIAA/uvrSiL4Bqc7LMAiaOqwv+PCLfYvXcL2ExAZIKHtReFy6sTrdfxdwrwr6y3AhCJCXtVH7qUFEnE2faaOEcZLj2bw0SKYQ3SdZhuTjNfAAA==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "corporate wellness talk at sap ariba 4",
            "title": "corporate wellness talk at sap ariba 4",
            "src": "/static/849f53ae01222180add49ccfb2365bf5/b0a15/corporate-wellness-talk-at-sap-ariba-4.webp",
            "srcSet": ["/static/849f53ae01222180add49ccfb2365bf5/c85cb/corporate-wellness-talk-at-sap-ariba-4.webp 300w", "/static/849f53ae01222180add49ccfb2365bf5/b0a15/corporate-wellness-talk-at-sap-ariba-4.webp 500w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      