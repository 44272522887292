import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import SEO from "./seo"
import Styles from "./layout-blog.module.css"

const Layout = ({ children, pageContext }) => {
  const data = useStaticQuery(graphql`
    query BlogPostQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  
  const attributor = (possibleAuthors, authorSingluar, authorPlural) => {
    const authorsPrefix = possibleAuthors && possibleAuthors.length > 1 ? authorPlural : authorSingluar
    const authorsNames = possibleAuthors && Array.isArray(possibleAuthors) ? possibleAuthors.join(', ') : possibleAuthors
    const authorsText = possibleAuthors ? authorsPrefix + ": " + authorsNames : ""
    return authorsText
  }

  const blogDate = new Date(pageContext.frontmatter.date)
  const authors = attributor(pageContext.frontmatter.authors, "Author", "Authors")
  const contributors = attributor(pageContext.frontmatter.contributors, "Contributor", "Contributors")
  return (
    <div className={Styles.wrapper}>
      <SEO title={pageContext.frontmatter.title} description={pageContext.frontmatter.description}/>
      <div className="header-background"></div>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="blog-heading">
        <h1>{pageContext.frontmatter.title}</h1>
        <div>Posted on {blogDate.toLocaleDateString('en-UK', {dateStyle: 'long'})}</div>
        <div>{authors}</div>
        <div>{contributors}</div>
      </div>
      <main className="content">{children}</main>
      <footer>

      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
